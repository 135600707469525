import { createPortal } from "react-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
type Props = {
  title: string;
  description: string;
  show: boolean;
};
export function ErrorMessagePopup({ title, description, show }: Props) {
  return show
    ? createPortal(
        <div
          className={
            "fixed max-w-md top-4 right-4 z-10 bg-red-400 p-3 rounded-md text-white flex space-x-2 items-center"
          }
        >
          <div className={"rounded-full p-2 bg-red-300"}>
            <ExclamationCircleIcon className={"h-7 w-7"} />
          </div>
          <div>
            <h4 className={"font-bold"}>{title}</h4>
            <div className={"font-light"}>{description}</div>
          </div>
        </div>,
        window.document.body,
      )
    : null;
}
