import { getFragmentData, graphql } from "../gql/index.ts";
import { ClassRoomWithoutParticipantFragment } from "./ClassRoomWithoutParticipantFragment.ts";
import { ProgressCheckUserFragment } from "./ProgressCheckUserFragment.ts";
import { useQuery } from "@apollo/client";

const GetProgressCheckUserListQuery = graphql(`
  query getProgressCheckUserList($progressCheckId: String!) {
    getProgressCheckUserList(progressCheckId: $progressCheckId) {
      progressCheck {
        ...ClassRoomWithoutParticipant
      }
      participants {
        ...ProgressCheckUser
      }
    }
  }
`);
export function useProgressCheckUserList(progressCheckId: string) {
  const { data, loading } = useQuery(GetProgressCheckUserListQuery, {
    variables: {
      progressCheckId,
    },
  });

  return {
    progressCheck: getFragmentData(
      ClassRoomWithoutParticipantFragment,
      data?.getProgressCheckUserList.progressCheck,
    ),
    participants:
      getFragmentData(
        ProgressCheckUserFragment,
        data?.getProgressCheckUserList.participants,
      ) ?? [],
    loading,
  };
}
