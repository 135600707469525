import { Logo } from "./Logo.tsx";

export function NavBar() {
  return (
    <div
      className={
        "p-4 min-h-16 bg-white shadow-xl shadow-slate-200/10 border-b border-slate-100 w-full flex items-center justify-between"
      }
    >
      <Logo />
    </div>
  );
}
