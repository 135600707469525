import { graphql } from "../gql";

export const ProgressCheckResultFragment = graphql(`
  fragment ProgressCheckResult on ProgressCheckResultDto {
    accuracy
    recommendation
    vocabularyRange
    fluency
    comprehension
    notes
    userId
    progressCheckId
    progressCheckStartedAtUtc
    progressCheckLevel
    passed
    noShow
  }
`);
