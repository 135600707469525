import { forwardRef, InputHTMLAttributes } from "react";
import { classNames } from "./Utils/classNames.ts";
export type TextInputProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  errorMessage?: string;
};
const TextAreaInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
  (props, ref) => {
    const { className, errorMessage, ...otherProps } = props;
    return (
      <div className={"w-full flex flex-col space-y-2"}>
        <textarea
          className={classNames(
            className,
            "border p-2 rounded-lg w-full min-h-[42px]",
            errorMessage ? "border-red-500" : "border-slate-200",
            otherProps.disabled
              ? "bg-slate-100 cursor-not-allowed"
              : "bg-white",
          )}
          {...otherProps}
          ref={ref}
        />
        {errorMessage ? <p className={"text-red-500"}>{errorMessage}</p> : null}
      </div>
    );
  },
);
export { TextAreaInput };
