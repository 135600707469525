import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren;
export function BreadCrumbsWrapper({ children }: Props) {
  return (
    <div className={"flex items-center space-x-2 text-sm text-slate-600/70"}>
      {React.Children.map(children, (child, index) => {
        return (
          <>
            {child}
            {index < React.Children.count(children) - 1 ? <span>/</span> : null}
          </>
        );
      })}
    </div>
  );
}
