import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GiveProgressCheckFeedbackThankYouScreenPath } from "./GiveProgressCheckFeedbackThankYouScreen.tsx";

export function useToProgressCheckFeedbackThankYou() {
  const navigate = useNavigate();
  const goToProgressCheckFeedbackThankYou = useCallback(
    (progressCheckId: string) => {
      navigate(
        GiveProgressCheckFeedbackThankYouScreenPath.replace(
          ":classRoomId",
          progressCheckId,
        ),
      );
    },
    [navigate],
  );

  return {
    goToProgressCheckFeedbackThankYou,
  };
}
