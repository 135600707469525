import { classNames } from "./Utils/classNames.ts";

type Props = {
  value: number;
  onChange: (value: number) => void;
  errorMessage?: string;
  disabled?: boolean;
};
export function RatingInput({
  value,
  onChange,
  errorMessage,
  disabled,
}: Props) {
  return (
    <div className={"space-y-2"}>
      <div className={"flex space-x-2 mt-2"}>
        {Array.from({
          length: 5,
        }).map((_, i) => {
          return (
            <div
              onClick={() => {
                if (disabled) return;
                onChange(i + 1);
              }}
              key={i}
              className={classNames(
                "rounded-full p-4 border-primary border-2",
                disabled
                  ? "opacity-30 cursor-not-allowed"
                  : value === i + 1
                    ? "bg-primary"
                    : "bg-white hover:bg-primary cursor-pointer",
              )}
            />
          );
        })}
      </div>
      {errorMessage ? <p className={"text-red-600"}>{errorMessage}</p> : null}
    </div>
  );
}
