export function Footer() {
  return (
    <div className={"bg-slate-700 p-4 flex justify-between text-white text-xs"}>
      <p className={"text-center"}>Made with ❤️ by Norma's Teaching S.R.L.</p>
      <div>
        <a
          target={"_blank"}
          rel={"noreferrer noopener"}
          href={"https://normasteaching.com/legal-info/"}
          className={"underline"}
        >
          Terms & Conditions
        </a>
      </div>
    </div>
  );
}
