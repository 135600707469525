import { graphql } from "../gql";

export const ProgressCheckUserFragment = graphql(`
  fragment ProgressCheckUser on ProgressCheckUserDto {
    id
    givenName
    familyName
    alreadyGivenFeedback
    canImproveSpeakingLevel
  }
`);
