import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export function useCreateApolloClient() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const createApolloClient = useCallback(() => {
    const httpLink = new HttpLink({
      uri: `${import.meta.env.VITE_API_BASE_URL}`,
    });
    const authLink = setContext(async (_, prevContext) => {
      const token = isAuthenticated
        ? await getAccessTokenSilently()
        : undefined;
      return {
        ...prevContext,
        headers: {
          ...prevContext.headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(httpLink),
    });
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    createApolloClient,
  };
}
