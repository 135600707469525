import { getFragmentData, graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";
import { ClassRoomFragment } from "./ClassRoomFragment.ts";

const GetClassRoomByIdQuery = graphql(`
  query getClassRoomById($classRoomId: String!) {
    getClassRoomById(classRoomId: $classRoomId) {
      ...ClassRoomFragment
    }
  }
`);
export function useClassRoomById(classRoomId: string) {
  const { data, loading } = useQuery(GetClassRoomByIdQuery, {
    variables: {
      classRoomId,
    },
  });

  return {
    classRoom: getFragmentData(ClassRoomFragment, data?.getClassRoomById),
    loading,
  };
}
