import { PropsWithChildren } from "react";
import { NavBar } from "./NavBar.tsx";
import { Footer } from "./Footer.tsx";

type Props = PropsWithChildren;
export function Layout({ children }: Props) {
  return (
    <div className={"flex flex-col min-h-screen"}>
      <NavBar />
      <div className={"flex-1 bg-slate-100 flex"}>
        <div
          className={
            "p-4 flex flex-col max-w-[1024px] mx-auto w-full min-h-full"
          }
        >
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}
