import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { ProgressCheckResultFragment } from "./ProgressCheckResultFragment.ts";

const GetProgressCheckResultByUserIdQuery = graphql(`
  query getProgressCheckResultByUserId($userId: String!) {
    getProgressCheckResultByUserId(userId: $userId) {
      ...ProgressCheckResult
    }
  }
`);
export function useProgressCheckResultByUserId(userId: string) {
  const { data, loading } = useQuery(GetProgressCheckResultByUserIdQuery, {
    variables: {
      userId,
    },
  });

  return {
    loading,
    progressCheckResults:
      getFragmentData(
        ProgressCheckResultFragment,
        data?.getProgressCheckResultByUserId,
      ) ?? [],
  };
}
