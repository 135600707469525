import { Layout } from "../UI/Layout/Layout.tsx";
import { useClassRoomById } from "../ClassRoom/useClassRoomById.ts";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { getFragmentData } from "../gql";
import { ClassRoomParticipantFragment } from "../ClassRoom/ClassRoomParticipantFragment.ts";
import { useCallback, useState } from "react";
import {
  ClassRoomFragmentFragment,
  ClassRoomParticipant,
  SpeakingClassLevel,
} from "../gql/graphql.ts";
import {
  GiveLessonFeedbackParticipantForm,
  GiveLessonFeedbackParticipantFormInput,
} from "./GiveLessonFeedbackParticipantForm.tsx";
import { useUpsertLessonFeedback } from "./useUpsertLessonFeedback.ts";
import _values from "lodash.values";
import { ProgressBar } from "../UI/ProgressBar.tsx";
import { Tabs } from "../UI/Tabs/Tabs.tsx";
import { TabItem } from "../UI/Tabs/TabItem.tsx";
import dayjs from "dayjs";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import { ErrorFeedback } from "../UI/Feedback/ErrorFeedback.tsx";
import { useClassRoomIdParamsOrThrow } from "../ClassRoom/useClassRoomIdParamsOrThrow.ts";
import { useToSentFeedbackThankYou } from "./useToSentFeedbackThankYou.ts";
import { Card } from "../UI/Card.tsx";

export const GiveLessonFeedbackScreenPath =
  "/classroom/:classRoomId/give-feedback";

type LessonFeedbackBagDto = {
  userId: string;
  targetLanguageScore: number;
  participationScore: number;
  notes: string;
  noShow: boolean;
  differentSpeakingLevelSuggested?: SpeakingClassLevel;
};

function GiveLessonFeedbackScreenInner({
  classRoom,
}: {
  classRoom: ClassRoomFragmentFragment;
}) {
  const { toSentFeedbackThankYou } = useToSentFeedbackThankYou();

  const [lessonFeedbacks, setLessonFeedbacks] = useState<
    Record<string, LessonFeedbackBagDto>
  >(
    classRoom.participants.reduce(
      (acc, p) => {
        const participant = getFragmentData(ClassRoomParticipantFragment, p);
        acc[participant.id] = {
          targetLanguageScore: 0,
          participationScore: 0,
          noShow: false,
          notes: "",
          userId: participant.id,
        };
        return acc;
      },
      {} as Record<string, LessonFeedbackBagDto>,
    ),
  );

  const [selectedParticipantIndex, setSelectedParticipantIndex] =
    useState<number>(0);

  const getSelectedParticipant = useCallback(
    (classRoom: ClassRoomFragmentFragment): ClassRoomParticipant => {
      const participant = classRoom.participants[selectedParticipantIndex];
      if (!participant)
        throw new Error(
          `Cannot find participant ${selectedParticipantIndex} for class room ${classRoom.id}`,
        );
      return getFragmentData(ClassRoomParticipantFragment, participant);
    },
    [selectedParticipantIndex],
  );

  function isCompletedLessonFeedback(
    lf: LessonFeedbackBagDto | undefined,
  ): boolean {
    if (!lf) return false;
    if (lf.noShow) return true;
    return lf.targetLanguageScore > 0 && lf.participationScore > 0;
  }
  const areSameLessonFeedbacks = useCallback(
    (
      a: LessonFeedbackBagDto | undefined,
      b: LessonFeedbackBagDto | undefined,
    ) => {
      return (
        a?.participationScore === b?.participationScore &&
        a?.targetLanguageScore === b?.targetLanguageScore &&
        a?.noShow === b?.noShow &&
        a?.notes === b?.notes &&
        a?.userId === b?.userId
      );
    },
    [],
  );

  const onChangeCurrentValuation = useCallback(
    (data: GiveLessonFeedbackParticipantFormInput) => {
      if (!classRoom) return;
      setLessonFeedbacks((prev) => {
        const selectedParticipant = getSelectedParticipant(classRoom);
        const newLf: LessonFeedbackBagDto = {
          targetLanguageScore: data.targetLanguageScore,
          participationScore: data.participationScore,
          notes: data.notes ?? "",
          userId: selectedParticipant.id,
          noShow: data.noShow,
          differentSpeakingLevelSuggested: data.differentSpeakingLevelSuggested,
        };
        if (areSameLessonFeedbacks(prev[selectedParticipant.id], newLf))
          return prev;
        return {
          ...prev,
          [selectedParticipant.id]: newLf,
        };
      });
    },
    [getSelectedParticipant, classRoom, areSameLessonFeedbacks],
  );
  const { upsertUserLessonFeedback } = useUpsertLessonFeedback();

  function hasCompletedFeedback(
    lessonFeedbacks: Record<string, LessonFeedbackBagDto>,
  ) {
    return _values(lessonFeedbacks).every(isCompletedLessonFeedback);
  }

  async function saveLessonFeedbacks(
    classRoom: ClassRoomFragmentFragment,
    feedbacks: LessonFeedbackBagDto[],
  ) {
    await upsertUserLessonFeedback({
      classRoomId: classRoom.id,
      lessonFeedbacks: feedbacks,
    });
  }

  function getFirstNotCompletedParticipantIndex(
    classRoom: ClassRoomFragmentFragment,
    lessonFeedbacks: Record<string, LessonFeedbackBagDto>,
  ): number {
    return classRoom.participants.findIndex(
      (p) =>
        !isCompletedLessonFeedback(
          lessonFeedbacks[getFragmentData(ClassRoomParticipantFragment, p).id],
        ),
    );
  }

  async function onSave(
    classRoom: ClassRoomFragmentFragment,
    data: GiveLessonFeedbackParticipantFormInput,
  ): Promise<void> {
    const selectedParticipant = getSelectedParticipant(classRoom);
    const newLessonFeedbacks: Record<string, LessonFeedbackBagDto> = {
      ...lessonFeedbacks,
      [selectedParticipant.id]: {
        targetLanguageScore: data.targetLanguageScore,
        participationScore: data.participationScore,
        notes: data.notes ?? "",
        userId: selectedParticipant.id,
        noShow: data.noShow,
        differentSpeakingLevelSuggested: data.differentSpeakingLevelSuggested,
      },
    };

    if (hasCompletedFeedback(newLessonFeedbacks)) {
      await saveLessonFeedbacks(classRoom, _values(newLessonFeedbacks));
      toSentFeedbackThankYou(classRoom.id);
    } else {
      setSelectedParticipantIndex(
        getFirstNotCompletedParticipantIndex(classRoom, newLessonFeedbacks),
      );
    }
    setLessonFeedbacks(newLessonFeedbacks);
  }

  const selectedParticipant = getSelectedParticipant(classRoom);

  function getDefaultValueByParticipantId(
    participantId: string,
  ): GiveLessonFeedbackParticipantFormInput {
    const values = lessonFeedbacks[participantId];
    if (values)
      return {
        notes: values.notes,
        participationScore: values.participationScore,
        targetLanguageScore: values.targetLanguageScore,
        noShow: values.noShow,
      };

    return {
      notes: "",
      participationScore: 0,
      targetLanguageScore: 0,
      noShow: false,
    };
  }

  function getSizeOfCompletedLessonFeedbacks(
    lessonFeedbacks: Record<string, LessonFeedbackBagDto>,
  ) {
    return _values(lessonFeedbacks).filter(isCompletedLessonFeedback).length;
  }

  function isTheLastOne(classRoom: ClassRoomFragmentFragment) {
    const size = getSizeOfCompletedLessonFeedbacks(lessonFeedbacks);
    const participant = getSelectedParticipant(classRoom);
    return (
      size >= classRoom.participants.length - 1 &&
      !isCompletedLessonFeedback(lessonFeedbacks[participant.id])
    );
  }
  return (
    <Layout>
      <div className={"space-y-3"}>
        <Card>
          <ScreenTitleBlock
            hideLineDivider
            title={`Lesson feedback for the lesson on ${dayjs(
              classRoom.startedAtUtc,
            ).format("dddd DD MMMM YYYY [at] HH:mm")}`}
            subTitle={
              <div>
                <p>
                  Topic name:{" "}
                  <span className={"font-semibold"}>
                    {classRoom.topic.name}
                  </span>
                </p>
                <p>
                  Speaking level:{" "}
                  <span className={"font-semibold"}>
                    {classRoom.speakingLevel}
                  </span>
                </p>
              </div>
            }
          />
        </Card>
        <Card>
          <p>
            To view the feedback provided by other teachers to the students in
            this class, please refer to{" "}
            <a
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={classRoom.feedbackReportUrl}
              className={"underline text-blue-600"}
            >
              this report
            </a>
          </p>
        </Card>
        <Card>
          <div>
            <ProgressBar
              label={"Feedback"}
              max={classRoom.participants.length}
              current={getSizeOfCompletedLessonFeedbacks(lessonFeedbacks)}
            />
          </div>
          <div>
            <Tabs>
              {classRoom.participants.map((p, i) => {
                const participant = getFragmentData(
                  ClassRoomParticipantFragment,
                  p,
                );
                return (
                  <TabItem
                    key={participant.id}
                    onClick={() => setSelectedParticipantIndex(i)}
                    selected={selectedParticipantIndex === i}
                    label={
                      <p className={"flex space-x-2"}>
                        <span>
                          {participant.givenName} {participant.familyName}
                        </span>
                        <span>
                          {isCompletedLessonFeedback(
                            lessonFeedbacks[participant.id],
                          )
                            ? "✅"
                            : "❌"}
                        </span>
                      </p>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
          <GiveLessonFeedbackParticipantForm
            onChange={onChangeCurrentValuation}
            key={selectedParticipant.id}
            defaultValues={getDefaultValueByParticipantId(
              selectedParticipant.id,
            )}
            onSave={(data) => onSave(classRoom, data)}
            saveButtonLabel={
              isTheLastOne(classRoom) || hasCompletedFeedback(lessonFeedbacks)
                ? "Send"
                : "Save and proceed"
            }
            participant={getSelectedParticipant(classRoom)}
          />
        </Card>
      </div>
    </Layout>
  );
}

export function GiveLessonFeedbackScreen() {
  const { classRoomId } = useClassRoomIdParamsOrThrow();
  const { classRoom, loading } = useClassRoomById(classRoomId);
  if (loading) return <FullPageLoading />;
  if (!classRoom)
    return (
      <Layout>
        <div className={"flex justify-center"}>
          <ErrorFeedback
            title={"Oops!"}
            message={"This class does not exist"}
          />
        </div>
      </Layout>
    );
  if (classRoom.participants.length <= 0)
    return (
      <Layout>
        <div className={"flex justify-center"}>
          <ErrorFeedback
            title={"Oops!"}
            message={"This class has no participants"}
          />
        </div>
      </Layout>
    );

  return <GiveLessonFeedbackScreenInner classRoom={classRoom} />;
}
