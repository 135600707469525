import { Layout } from "../UI/Layout/Layout.tsx";
import { useClassRoomIdParamsOrThrow } from "../ClassRoom/useClassRoomIdParamsOrThrow.ts";
import { useClassRoomById } from "../ClassRoom/useClassRoomById.ts";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import { ErrorFeedback } from "../UI/Feedback/ErrorFeedback.tsx";
import { getFragmentData } from "../gql/index.ts";
import { ClassRoomParticipantFragment } from "../ClassRoom/ClassRoomParticipantFragment.ts";
import dayjs from "dayjs";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { useToUserLessonFeedback } from "./useToUserLessonFeedback.ts";
import { Card } from "../UI/Card.tsx";

export const LessonFeedbackReportUserListScreenPath =
  "/classroom/:classRoomId/feedback-report";
export function LessonFeedbackReportUserListScreen() {
  const { classRoomId } = useClassRoomIdParamsOrThrow();
  const { classRoom, loading } = useClassRoomById(classRoomId);

  const { toUserLessonFeedbackReport } = useToUserLessonFeedback();
  if (loading) return <FullPageLoading />;
  if (!classRoom)
    return (
      <Layout>
        <div className={"flex justify-center"}>
          <ErrorFeedback
            title={"Oops!"}
            message={"This classroom does not exist"}
          />
        </div>
      </Layout>
    );

  if (classRoom.participants.length <= 0)
    return (
      <Layout>
        <div className={"flex justify-center"}>
          <ErrorFeedback
            title={"Oops!"}
            message={"This classroom has no participants"}
          />
        </div>
      </Layout>
    );

  return (
    <Layout>
      <div className={"space-y-4"}>
        <Card>
          <ScreenTitleBlock
            hideLineDivider
            title={`Lesson feedback for the lesson on ${dayjs(
              classRoom.startedAtUtc,
            ).format("dddd DD MMMM YYYY [at] HH:mm")}`}
            subTitle={
              <div>
                <p>Speaking level: {classRoom.speakingLevel}</p>
              </div>
            }
          />
        </Card>
        <div className={"space-y-4"}>
          {classRoom.participants.map((p) => {
            const participant = getFragmentData(
              ClassRoomParticipantFragment,
              p,
            );
            return (
              <div
                className={
                  "shadow-lg bg-white shadow-slate-200/50 border border-slate-100/50 rounded-lg p-4 flex items-center justify-between space-x-2"
                }
                key={participant.id}
              >
                <h4 className={"font-bold"}>
                  {participant.givenName} {participant.familyName}
                </h4>
                <PrimaryButton
                  onClick={() =>
                    toUserLessonFeedbackReport(participant.id, classRoomId)
                  }
                  label={"View report"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
