import { ProgressCheckRecommendation } from "./ProgressCheckRecommendation.tsx";

export function getRecommendationLabel(r: ProgressCheckRecommendation) {
  switch (r) {
    case ProgressCheckRecommendation.MoreAccuracy:
      return "Accuracy: When they speak do they make an appropriate amount of mistakes for their level? The types of mistakes we want to look out for are grammar, pronunciation or word choice.";
    case ProgressCheckRecommendation.MoreFluency:
      return "Fluency: Are they able to speak at length without getting stuck?";
    case ProgressCheckRecommendation.MoreImmersion:
      return "Comprehension: Are they able to understand all of the topics in their current level or do they struggle with understanding their peers/questions?";
    case ProgressCheckRecommendation.MoreVocabulary:
      return "Range of vocabulary: Do they always use the same words and expressions or are they able to vary a bit?";
    default:
      throw new Error(`Unknown recommendation: ${r}`);
  }
}
