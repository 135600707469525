import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { UserLessonFeedbackReportScreenPath } from "./UserLessonFeedbackReportScreen.tsx";

export function useToUserLessonFeedback() {
  const navigate = useNavigate();

  const toUserLessonFeedbackReport = useCallback(
    (userId: string, classRoomId: string) => {
      navigate(
        UserLessonFeedbackReportScreenPath.replace(":userId", userId).replace(
          ":classRoomId",
          classRoomId,
        ),
      );
    },
    [navigate],
  );

  return {
    toUserLessonFeedbackReport,
  };
}
