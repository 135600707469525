import { graphql } from "../gql/index.ts";

export const ClassRoomFragment = graphql(`
  fragment ClassRoomFragment on ClassRoom {
    id
    topic {
      name
      id
      pdfUrl
    }
    speakingLevel
    startedAtUtc
    endedAtUtc
    participants {
      ...ClassRoomParticipant
    }
    feedbackReportUrl
  }
`);
