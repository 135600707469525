import { CheckBox } from "./CheckBox.tsx";

export type CheckBoxGroupItem<T extends string> = {
  label: string;
  id: T;
  value: boolean;
};
export function CheckBoxGroup<T extends string>({
  items,
  onChange,
  errorMessage,
  disabled,
}: {
  items: CheckBoxGroupItem<T>[];
  onChange: (values: CheckBoxGroupItem<T>[]) => void;
  errorMessage?: string;
  disabled?: boolean;
}) {
  return (
    <div className={"flex flex-col space-y-3"}>
      {items.map((i) => (
        <CheckBox
          disabled={disabled}
          key={i.id}
          value={i.value}
          onChange={(v) => {
            onChange(
              items.map((item) =>
                item.id === i.id ? { ...item, value: v } : item,
              ),
            );
          }}
          label={i.label}
        />
      ))}
      {errorMessage ? <p className={"text-red-500"}>{errorMessage}</p> : null}
    </div>
  );
}
