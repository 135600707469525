import { ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";

export function TabItem({
  label,
  selected,
  onClick,
}: {
  label: string | ReactNode;
  selected?: boolean;
  onClick: () => void;
}) {
  return (
    <li className="me-2 flex-shrink-0">
      <a
        aria-current="page"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className={classNames(
          "cursor-pointer inline-block p-4 rounded-t-lg active",
          selected
            ? "text-blue-600  bg-gray-100"
            : "hover:text-gray-600 hover:bg-gray-50",
        )}
      >
        {label}
      </a>
    </li>
  );
}
