import { graphql } from "../gql";

export const ClassRoomParticipantFragment = graphql(`
  fragment ClassRoomParticipant on ClassRoomParticipant {
    id
    givenName
    familyName
    executedClassRoomCount
    speakingLevel
  }
`);
