import { CenteredLoading } from "./CenteredLoading.tsx";
import { Layout } from "../Layout/Layout.tsx";

export function FullPageLoading() {
  return (
    <Layout>
      <div className={"w-full h-full flex items-center justify-center"}>
        <CenteredLoading />
      </div>
    </Layout>
  );
}
