import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { GiveProgressCheckFeedbackInput } from "../gql/graphql.ts";
import { useCallback } from "react";

const GiveProgressCheckFeedbackMutation = graphql(`
  mutation giveProgressCheckFeedback(
    $input: [GiveProgressCheckFeedbackInput!]!
  ) {
    giveProgressCheckFeedback(input: $input)
  }
`);
export function useGiveProgressCheckFeedback() {
  const [giveProgressCheckFeedbackMutation, { loading }] = useMutation(
    GiveProgressCheckFeedbackMutation,
  );

  const giveProgressCheckFeedback = useCallback(
    async (input: GiveProgressCheckFeedbackInput[]) => {
      await giveProgressCheckFeedbackMutation({
        variables: {
          input,
        },
      });
    },
    [giveProgressCheckFeedbackMutation],
  );

  return {
    giveProgressCheckFeedback,
    loading,
  };
}
