/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type AgGridParamsInput = {
  endRow: Scalars["Int"]["input"];
  filterModel: Scalars["JSON"]["input"];
  groupKeys: Array<Scalars["String"]["input"]>;
  queryId: Scalars["String"]["input"];
  rowGroupCols: Scalars["JSON"]["input"];
  sortModel: Scalars["JSON"]["input"];
  startRow: Scalars["Int"]["input"];
  valueCols: Scalars["JSON"]["input"];
};

export type AgGridQueryResponse = {
  data: Scalars["JSON"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export type AvailabilitySlotDto = {
  endDateUtc: Scalars["String"]["output"];
  slotId: Scalars["String"]["output"];
  slotTimezone: Scalars["String"]["output"];
  startDateUtc: Scalars["String"]["output"];
};

export type BookSlotClassRoomInput = {
  id: Scalars["String"]["input"];
};

export type BookSlotInput = {
  classRoom?: InputMaybe<BookSlotClassRoomInput>;
  endDateUtc: Scalars["String"]["input"];
  slotId: Scalars["String"]["input"];
  startDateUtc: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
};

export type BookableSlot = {
  capacity: Scalars["Int"]["output"];
  classRoom?: Maybe<BookableSlotClassRoom>;
  endDateUtc: Scalars["String"]["output"];
  hasEnoughRemainingLessons: Scalars["Boolean"]["output"];
  participantsCount: Scalars["Int"]["output"];
  slotId: Scalars["ID"]["output"];
  slotLevel: SpeakingClassLevel;
  slotTimezone: Scalars["String"]["output"];
  slotType: ClassRoomType;
  startDateUtc: Scalars["String"]["output"];
  topic: Topic;
  userIsAParticipant: Scalars["Boolean"]["output"];
};

export type BookableSlotClassRoom = {
  canBeLeaved: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
};

export type ClassRoom = {
  capacity: Scalars["Int"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUrl: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUrl: Scalars["String"]["output"];
  participants: Array<ClassRoomParticipant>;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  topic: Topic;
};

export type ClassRoomListItem = {
  capacity: Scalars["Float"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  completedFeedbacksCount: Scalars["Int"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
  participantsCount: Scalars["Float"]["output"];
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  teacher: ClassRoomTeacher;
  topic: Topic;
  type: ClassRoomType;
};

export type ClassRoomParticipant = {
  executedClassRoomCount: Scalars["Int"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  speakingLevel: SpeakingClassLevel;
};

export type ClassRoomReviewDto = {
  classRoom: ClassRoomWithoutParticipantDto;
  classRoomId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  reviewNotes: Scalars["String"]["output"];
  reviewedAtUtc: Scalars["String"]["output"];
  teacherId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type ClassRoomTeacher = {
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  phone?: Maybe<PhoneNumber>;
};

/** The class room type such as speaking class or exam */
export enum ClassRoomType {
  Exam = "Exam",
  SpeakingClass = "SpeakingClass",
}

export type ClassRoomV2 = {
  canBeLeaved: Scalars["Boolean"]["output"];
  capacity: Scalars["Float"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
  participantsCount: Scalars["Float"]["output"];
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  teacher: Franchisee;
  teacherId: Scalars["String"]["output"];
  topic: Topic;
  type: ClassRoomType;
};

export type ClassRoomWithoutParticipantDto = {
  canBeLeaved: Scalars["Boolean"]["output"];
  capacity: Scalars["Float"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUrl: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
  participantsCount: Scalars["Float"]["output"];
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  topic: Topic;
  type: ClassRoomType;
};

export type CompleteClassRoom = {
  capacity: Scalars["Int"]["output"];
  conferenceUri: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUrl: Scalars["String"]["output"];
  feedbackUrl: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUrl: Scalars["String"]["output"];
  participants: Array<CompleteClassRoomParticipant>;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  teacher: ClassRoomTeacher;
  topic: Topic;
};

export type CompleteClassRoomParticipant = {
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type Franchisee = {
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  phoneNumber?: Maybe<PhoneNumber>;
  shiftBaseId: Scalars["String"]["output"];
};

export type FrozenWeekDto = {
  endOfWeekUtc: Scalars["String"]["output"];
  startOfWeekUtc: Scalars["String"]["output"];
};

export type FrozenWeekInput = {
  endOfWeekDate: Scalars["String"]["input"];
  startOfWeekDate: Scalars["String"]["input"];
};

export type GetFreezableWeeksResponse = {
  remainingWeeksToFreeze: Array<RemainingWeeksToFreeze>;
  weeks: Array<WeekWithPlan>;
};

export type GetMyRemainingLessonResponse = {
  remainingLessons: Scalars["Int"]["output"];
  totalAvailableLessons: Scalars["Int"]["output"];
};

export type GetProgressCheckAvailabilityGraphqlResponse = {
  hasAtLeastOnProgressCheckBookable: Scalars["Boolean"]["output"];
  slots: Array<BookableSlot>;
};

export type GetProgressCheckUserListResponse = {
  participants: Array<ProgressCheckUserDto>;
  progressCheck: ClassRoomWithoutParticipantDto;
};

export type GetSpeakingClassAvailabilityResponse = {
  slots: Array<BookableSlot>;
};

export type GetUserClassRoomCountResponse = {
  totalClassRoomCount: Scalars["Int"]["output"];
};

export type GetUserRemainingLessonCountByWeekResponse = {
  remainingLessonCount: Scalars["Int"]["output"];
  totalLessonCount: Scalars["Int"]["output"];
};

export type GiveProgressCheckFeedbackInput = {
  accuracy: Scalars["Int"]["input"];
  comprehension: Scalars["Int"]["input"];
  fluency: Scalars["Int"]["input"];
  noShow?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  passed: Scalars["Boolean"]["input"];
  progressCheckId: Scalars["String"]["input"];
  recommendation: Array<ProgressCheckRecommendation>;
  userId: Scalars["String"]["input"];
  vocabularyRange: Scalars["Int"]["input"];
};

export type HasUnlockedProgressCheckResponse = {
  hasUnlockedProgressCheck: Scalars["Boolean"]["output"];
  lessonCompletedToUnlockProgressCheck: Scalars["Int"]["output"];
  totalLessonNeededToUnlockProgressCheck: Scalars["Int"]["output"];
};

export type LevelChangeSuggestionDto = {
  id: Scalars["String"]["output"];
  suggestedLevels: Array<SpeakingClassLevel>;
  user: User;
  userId: Scalars["String"]["output"];
};

export type ListClassRoomDateInput = {
  fromDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type ListClassRoomResponse = {
  classRooms: Array<ClassRoomListItem>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPrevPage: Scalars["Boolean"]["output"];
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
  prevPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type ListUserResponse = {
  hasNextPage: Scalars["Boolean"]["output"];
  hasPrevPage: Scalars["Boolean"]["output"];
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
  prevPageToken?: Maybe<Scalars["String"]["output"]>;
  users: Array<User>;
};

export type Mutation = {
  addFrozenWeek: Scalars["Boolean"]["output"];
  addFrozenWeekToUser: Scalars["Boolean"]["output"];
  addParticipantsToClassByEmail: ClassRoom;
  bookProgressCheck: Scalars["Boolean"]["output"];
  bookSpeakingClass: Scalars["Boolean"]["output"];
  createClassRoom: ClassRoom;
  createClassRoomPlan: UserClassRoomPlan;
  createFranchisee: Franchisee;
  createSeedUsers: Array<User>;
  createTopic: Topic;
  createUser: User;
  deleteClassRoom: Scalars["Boolean"]["output"];
  deleteClassRoomPlan: Scalars["Boolean"]["output"];
  deleteLessonFeedback: Scalars["Boolean"]["output"];
  deleteMe: Scalars["Boolean"]["output"];
  editTopic: Topic;
  enrollUserToAllPaidContent: Scalars["Boolean"]["output"];
  giveProgressCheckFeedback: Scalars["Boolean"]["output"];
  leaveClassRoom: ClassRoomWithoutParticipantDto;
  leaveClassRoomReview: ClassRoomReviewDto;
  logicallyDeleteTopic: Scalars["Boolean"]["output"];
  removeAvailabilitySlot: Scalars["Boolean"]["output"];
  removeFrozenWeek: Scalars["Boolean"]["output"];
  removeFrozenWeekForUser: Scalars["Boolean"]["output"];
  removeParticipantFromClassRoom: RemoveParticipantFromClassRoomResponse;
  resolveLevelSuggestion: Scalars["Boolean"]["output"];
  seedUserClassRoomPlans: Array<UserClassRoomPlan>;
  sendResetPasswordEmail: Scalars["Boolean"]["output"];
  swapTeacher: Scalars["Boolean"]["output"];
  updateLastLogin: Scalars["Boolean"]["output"];
  updateMe: User;
  updateTeacher: Franchisee;
  updateUser: User;
  updateUserLevelToNext: User;
  upsertLessonFeedback: Scalars["Boolean"]["output"];
};

export type MutationAddFrozenWeekArgs = {
  week: FrozenWeekInput;
};

export type MutationAddFrozenWeekToUserArgs = {
  userId: Scalars["String"]["input"];
  week: FrozenWeekInput;
};

export type MutationAddParticipantsToClassByEmailArgs = {
  classRoomId: Scalars["String"]["input"];
  userEmails: Array<Scalars["String"]["input"]>;
};

export type MutationBookProgressCheckArgs = {
  bookSlotInput: BookSlotInput;
};

export type MutationBookSpeakingClassArgs = {
  bookSlotInput: BookSlotInput;
};

export type MutationCreateClassRoomArgs = {
  capacity: Scalars["Int"]["input"];
  classRoomType: ClassRoomType;
  participantIds: Array<Scalars["String"]["input"]>;
  speakingLevel: SpeakingClassLevel;
  startUtc: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
};

export type MutationCreateClassRoomPlanArgs = {
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationCreateFranchiseeArgs = {
  email: Scalars["String"]["input"];
  familyName: Scalars["String"]["input"];
  givenName: Scalars["String"]["input"];
  phoneNumber: PhoneNumberInput;
};

export type MutationCreateSeedUsersArgs = {
  count: Scalars["Float"]["input"];
  speakingLevel: SpeakingLevel;
};

export type MutationCreateTopicArgs = {
  level: SpeakingClassLevel;
  nameIt: Scalars["String"]["input"];
  pdfUrl: Scalars["String"]["input"];
};

export type MutationCreateUserArgs = {
  activateAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  email: Scalars["String"]["input"];
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  hideBookProgressCheckSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookSpeakingClassSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  speakingLevel: SpeakingLevel;
};

export type MutationDeleteClassRoomArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type MutationDeleteClassRoomPlanArgs = {
  planId: Scalars["String"]["input"];
};

export type MutationDeleteLessonFeedbackArgs = {
  lessonFeedbackId: Scalars["String"]["input"];
};

export type MutationEditTopicArgs = {
  id: Scalars["String"]["input"];
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  pdfUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationEnrollUserToAllPaidContentArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationGiveProgressCheckFeedbackArgs = {
  input: Array<GiveProgressCheckFeedbackInput>;
};

export type MutationLeaveClassRoomArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type MutationLeaveClassRoomReviewArgs = {
  classRoomId: Scalars["String"]["input"];
  generalLessonScore: Scalars["Int"]["input"];
  reviewNotes: Scalars["String"]["input"];
  teacherClearanceScore: Scalars["Int"]["input"];
  teacherComfortableScore: Scalars["Int"]["input"];
  teacherInvolvementScore: Scalars["Int"]["input"];
  topicDifficultyScore: Scalars["Int"]["input"];
  topicUsefulnessScore: Scalars["Int"]["input"];
};

export type MutationLogicallyDeleteTopicArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveAvailabilitySlotArgs = {
  slotId: Scalars["String"]["input"];
};

export type MutationRemoveFrozenWeekArgs = {
  week: FrozenWeekInput;
};

export type MutationRemoveFrozenWeekForUserArgs = {
  userId: Scalars["String"]["input"];
  week: FrozenWeekInput;
};

export type MutationRemoveParticipantFromClassRoomArgs = {
  classRoomId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationResolveLevelSuggestionArgs = {
  suggestionId: Scalars["String"]["input"];
};

export type MutationSeedUserClassRoomPlansArgs = {
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
};

export type MutationSwapTeacherArgs = {
  classRoomId: Scalars["String"]["input"];
  doNotMakeOldTeacherAvailableAgain: Scalars["Boolean"]["input"];
  teacherToAddId: Scalars["String"]["input"];
};

export type MutationUpdateMeArgs = {
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateTeacherArgs = {
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type MutationUpdateUserArgs = {
  activateAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  hideBookProgressCheckSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookSpeakingClassSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  speakingLevel?: InputMaybe<SpeakingLevel>;
  userId: Scalars["String"]["input"];
};

export type MutationUpdateUserLevelToNextArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationUpsertLessonFeedbackArgs = {
  classRoomId: Scalars["String"]["input"];
  lessonFeedback: Array<UpsertUserLessonFeedbackInput>;
};

export type PhoneNumber = {
  countryCode: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
};

export type PhoneNumberInput = {
  countryCode: Scalars["String"]["input"];
  number: Scalars["String"]["input"];
};

export enum ProgressCheckRecommendation {
  MoreAccuracy = "MoreAccuracy",
  MoreFluency = "MoreFluency",
  MoreImmersion = "MoreImmersion",
  MoreVocabulary = "MoreVocabulary",
}

export type ProgressCheckResultDto = {
  accuracy: Scalars["Int"]["output"];
  comprehension: Scalars["Int"]["output"];
  fluency: Scalars["Int"]["output"];
  noShow: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  passed: Scalars["Boolean"]["output"];
  progressCheckId: Scalars["String"]["output"];
  progressCheckLevel: SpeakingClassLevel;
  progressCheckStartedAtUtc: Scalars["String"]["output"];
  recommendation: Array<ProgressCheckRecommendation>;
  teacher: ClassRoomTeacher;
  teacherId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
  vocabularyRange: Scalars["Int"]["output"];
};

export type ProgressCheckUserDto = {
  alreadyGivenFeedback: Scalars["Boolean"]["output"];
  canImproveSpeakingLevel: Scalars["Boolean"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type Query = {
  adminGetClassRoomById: CompleteClassRoom;
  calculatePlanGivenSlotByParams: Scalars["Int"]["output"];
  getAllActiveUsersByIds: Array<Maybe<User>>;
  getAllByShiftBaseUserIds: Array<Franchisee>;
  getAllFranchisees: Array<Franchisee>;
  getAllTeacherByIds: Array<Franchisee>;
  getAllTopics: Array<Topic>;
  getAllUsersByIds: Array<Maybe<User>>;
  getAvailabilityByTeacherForWeek: Array<AvailabilitySlotDto>;
  getAvailabilityForProgressCheck: GetProgressCheckAvailabilityGraphqlResponse;
  getAvailabilityForSpeakingClass: GetSpeakingClassAvailabilityResponse;
  getAvailableTeachersInHour: Array<ClassRoomTeacher>;
  getByAuthId: User;
  getClassRoomById: ClassRoom;
  getClassRoomByIdV2: ClassRoomV2;
  getFranchiseeById: Franchisee;
  getFreezableWeeks: GetFreezableWeeksResponse;
  getFreezableWeeksByUser: GetFreezableWeeksResponse;
  getFrozenWeeksByUser: Array<FrozenWeekDto>;
  getLessonFeedbackReportByUser: UserLessonFeedbackReport;
  getMinUsedTopicsInLevelAmongParticipants: Array<Topic>;
  getMyFrozenWeeks: Array<FrozenWeekDto>;
  getMyRemainingLessons: GetMyRemainingLessonResponse;
  getProgressCheckResultByUserId: Array<ProgressCheckResultDto>;
  getProgressCheckUserList: GetProgressCheckUserListResponse;
  getTeacherClassRooms: Array<ClassRoomWithoutParticipantDto>;
  getTopicById: Topic;
  getUserByEmailEvenIfDeleted: User;
  getUserById: User;
  getUserClassRoomCount: GetUserClassRoomCountResponse;
  getUserClassRoomPlans: Array<UserClassRoomPlan>;
  getUserClassRoomsBetweenDates: Array<ClassRoomWithoutParticipantDto>;
  getUserRemainingLessonByWeek: GetUserRemainingLessonCountByWeekResponse;
  getUserRemainingLessons: Scalars["Int"]["output"];
  hasSpeakingEnabled: Scalars["Boolean"]["output"];
  hasUnlockedProgressCheck: HasUnlockedProgressCheckResponse;
  isActiveUser: Scalars["Boolean"]["output"];
  isActiveUserForAdminById: Scalars["Boolean"]["output"];
  isFrozenWeek: Scalars["Boolean"]["output"];
  listAllUsers: ListUserResponse;
  listClassRooms: ListClassRoomResponse;
  listNotResolvedLevelChangeSuggestions: Array<LevelChangeSuggestionDto>;
  me?: Maybe<User>;
  safelyGetUserByEmail?: Maybe<User>;
  searchTeachersByEmail: Array<Franchisee>;
  speakingClassServiceAgGridQuery: AgGridQueryResponse;
};

export type QueryAdminGetClassRoomByIdArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type QueryCalculatePlanGivenSlotByParamsArgs = {
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
};

export type QueryGetAllActiveUsersByIdsArgs = {
  userIds: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetAllByShiftBaseUserIdsArgs = {
  shiftBaseUserIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetAllTeacherByIdsArgs = {
  ids: Array<Scalars["String"]["input"]>;
};

export type QueryGetAllUsersByIdsArgs = {
  userIds: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetAvailabilityByTeacherForWeekArgs = {
  fromDate: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetAvailabilityForProgressCheckArgs = {
  clientTimezone: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetAvailabilityForSpeakingClassArgs = {
  clientTimezone: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetAvailableTeachersInHourArgs = {
  startUtc: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetByAuthIdArgs = {
  authId: Scalars["String"]["input"];
};

export type QueryGetClassRoomByIdArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type QueryGetClassRoomByIdV2Args = {
  classRoomId: Scalars["String"]["input"];
};

export type QueryGetFranchiseeByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetFreezableWeeksByUserArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetFrozenWeeksByUserArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetLessonFeedbackReportByUserArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetMinUsedTopicsInLevelAmongParticipantsArgs = {
  participantIds: Array<Scalars["String"]["input"]>;
  speakingLevel: SpeakingClassLevel;
};

export type QueryGetProgressCheckResultByUserIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetProgressCheckUserListArgs = {
  progressCheckId: Scalars["String"]["input"];
};

export type QueryGetTeacherClassRoomsArgs = {
  fromDate: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetTopicByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetUserByEmailEvenIfDeletedArgs = {
  email: Scalars["String"]["input"];
};

export type QueryGetUserByIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetUserClassRoomPlansArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetUserClassRoomsBetweenDatesArgs = {
  clientTimezone: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetUserRemainingLessonByWeekArgs = {
  clientTimezone: Scalars["String"]["input"];
  dateUtc: Scalars["String"]["input"];
};

export type QueryGetUserRemainingLessonsArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryIsActiveUserForAdminByIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryIsFrozenWeekArgs = {
  week: FrozenWeekInput;
};

export type QueryListAllUsersArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  prevPageToken?: InputMaybe<Scalars["String"]["input"]>;
  withSpeakingLevel?: InputMaybe<Array<InputMaybe<SpeakingLevel>>>;
};

export type QueryListClassRoomsArgs = {
  dateRange?: InputMaybe<ListClassRoomDateInput>;
  limit: Scalars["Int"]["input"];
  nextToken?: InputMaybe<Scalars["String"]["input"]>;
  prevToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySafelyGetUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

export type QuerySearchTeachersByEmailArgs = {
  filter: Scalars["String"]["input"];
};

export type QuerySpeakingClassServiceAgGridQueryArgs = {
  params: AgGridParamsInput;
};

export type RemainingWeeksToFreeze = {
  planId: Scalars["String"]["output"];
  remainingCount: Scalars["Float"]["output"];
};

export type RemoveParticipantFromClassRoomResponse = {
  classRoom: ClassRoom;
  classRoomDeleted: Scalars["Boolean"]["output"];
};

export enum SpeakingClassLevel {
  Explorers = "Explorers",
  Navigators = "Navigators",
  Pioneers = "Pioneers",
  Starters = "Starters",
  Voyagers = "Voyagers",
}

/** The user speaking level */
export enum SpeakingLevel {
  Explorers = "Explorers",
  Navigators = "Navigators",
  Pioneers = "Pioneers",
  Starters = "Starters",
  Unknown = "Unknown",
  Voyagers = "Voyagers",
}

export type Topic = {
  id: Scalars["String"]["output"];
  level: SpeakingClassLevel;
  name: Scalars["String"]["output"];
  pdfUrl: Scalars["String"]["output"];
};

export type UpsertUserLessonFeedbackInput = {
  differentSpeakingLevelSuggested?: InputMaybe<SpeakingClassLevel>;
  noShow: Scalars["Boolean"]["input"];
  notes: Scalars["String"]["input"];
  participationScore: Scalars["Int"]["input"];
  targetLanguageScore: Scalars["Int"]["input"];
  userId: Scalars["String"]["input"];
};

export type User = {
  authId: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  hideBookProgressCheckSection: Scalars["Boolean"]["output"];
  hideBookSpeakingClassSection: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lessonFeedbacks: Array<UserLessonFeedbackDto>;
  progressCheckResults: Array<ProgressCheckResultDto>;
  speakingLevel: SpeakingLevel;
};

export type UserClassRoomPlan = {
  endedAtWeekUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lessonsPerWeek: Scalars["Float"]["output"];
  numberOfSlotIncluded: Scalars["Float"]["output"];
  startedAtWeekUtc: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type UserLessonFeedbackDto = {
  classRoomEndDateUtc: Scalars["String"]["output"];
  classRoomStartDateUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  noShow: Scalars["Boolean"]["output"];
  notes: Scalars["String"]["output"];
  participation: Scalars["Int"]["output"];
  targetLanguage: Scalars["Int"]["output"];
  teacher: ClassRoomTeacher;
  teacherId: Scalars["String"]["output"];
};

export type UserLessonFeedbackNote = {
  classRoomStartDateUtc: Scalars["String"]["output"];
  classRoomTopicName: Scalars["String"]["output"];
  note: Scalars["String"]["output"];
};

export type UserLessonFeedbackReport = {
  avgParticipationScore: Scalars["Float"]["output"];
  avgTargetLanguageScore: Scalars["Float"]["output"];
  hasAtLeastOneFeedback: Scalars["Boolean"]["output"];
  notes: Array<UserLessonFeedbackNote>;
  userFamilyName: Scalars["String"]["output"];
  userGivenName: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type WeekWithPlan = {
  planId: Scalars["String"]["output"];
  week: FrozenWeekDto;
};

export type ClassRoomFragmentFragment = {
  id: string;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: string;
  endedAtUtc: string;
  feedbackReportUrl: string;
  topic: { name: string; id: string; pdfUrl: string };
  participants: Array<{
    " $fragmentRefs"?: {
      ClassRoomParticipantFragment: ClassRoomParticipantFragment;
    };
  }>;
} & { " $fragmentName"?: "ClassRoomFragmentFragment" };

export type ClassRoomParticipantFragment = {
  id: string;
  givenName: string;
  familyName: string;
  executedClassRoomCount: number;
  speakingLevel: SpeakingClassLevel;
} & { " $fragmentName"?: "ClassRoomParticipantFragment" };

export type GetClassRoomByIdQueryVariables = Exact<{
  classRoomId: Scalars["String"]["input"];
}>;

export type GetClassRoomByIdQuery = {
  getClassRoomById: {
    " $fragmentRefs"?: { ClassRoomFragmentFragment: ClassRoomFragmentFragment };
  };
};

export type LessonFeedbackReportFragmentFragment = {
  avgParticipationScore: number;
  avgTargetLanguageScore: number;
  hasAtLeastOneFeedback: boolean;
  userGivenName: string;
  userFamilyName: string;
  notes: Array<{
    classRoomStartDateUtc: string;
    classRoomTopicName: string;
    note: string;
  }>;
} & { " $fragmentName"?: "LessonFeedbackReportFragmentFragment" };

export type UpsertLessonFeedbackMutationVariables = Exact<{
  classRoomId: Scalars["String"]["input"];
  lessonFeedbacks:
    | Array<UpsertUserLessonFeedbackInput>
    | UpsertUserLessonFeedbackInput;
}>;

export type UpsertLessonFeedbackMutation = { upsertLessonFeedback: boolean };

export type GetLessonFeedbackReportByUserQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetLessonFeedbackReportByUserQuery = {
  getLessonFeedbackReportByUser: {
    " $fragmentRefs"?: {
      LessonFeedbackReportFragmentFragment: LessonFeedbackReportFragmentFragment;
    };
  };
};

export type ClassRoomWithoutParticipantFragment = {
  id: string;
  startedAtUtc: string;
  speakingLevel: SpeakingClassLevel;
  feedbackReportUrl: string;
} & { " $fragmentName"?: "ClassRoomWithoutParticipantFragment" };

export type ProgressCheckResultFragment = {
  accuracy: number;
  recommendation: Array<ProgressCheckRecommendation>;
  vocabularyRange: number;
  fluency: number;
  comprehension: number;
  notes?: string | null;
  userId: string;
  progressCheckId: string;
  progressCheckStartedAtUtc: string;
  progressCheckLevel: SpeakingClassLevel;
  passed: boolean;
  noShow: boolean;
} & { " $fragmentName"?: "ProgressCheckResultFragment" };

export type ProgressCheckUserFragment = {
  id: string;
  givenName: string;
  familyName: string;
  alreadyGivenFeedback: boolean;
  canImproveSpeakingLevel: boolean;
} & { " $fragmentName"?: "ProgressCheckUserFragment" };

export type GiveProgressCheckFeedbackMutationVariables = Exact<{
  input: Array<GiveProgressCheckFeedbackInput> | GiveProgressCheckFeedbackInput;
}>;

export type GiveProgressCheckFeedbackMutation = {
  giveProgressCheckFeedback: boolean;
};

export type GetProgressCheckResultByUserIdQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetProgressCheckResultByUserIdQuery = {
  getProgressCheckResultByUserId: Array<{
    " $fragmentRefs"?: {
      ProgressCheckResultFragment: ProgressCheckResultFragment;
    };
  }>;
};

export type GetProgressCheckUserListQueryVariables = Exact<{
  progressCheckId: Scalars["String"]["input"];
}>;

export type GetProgressCheckUserListQuery = {
  getProgressCheckUserList: {
    progressCheck: {
      " $fragmentRefs"?: {
        ClassRoomWithoutParticipantFragment: ClassRoomWithoutParticipantFragment;
      };
    };
    participants: Array<{
      " $fragmentRefs"?: {
        ProgressCheckUserFragment: ProgressCheckUserFragment;
      };
    }>;
  };
};

export const ClassRoomParticipantFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomParticipant" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "executedClassRoomCount" },
          },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomParticipantFragment, unknown>;
export const ClassRoomFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomParticipant" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "feedbackReportUrl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomParticipant" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "executedClassRoomCount" },
          },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomFragmentFragment, unknown>;
export const LessonFeedbackReportFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LessonFeedbackReportFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserLessonFeedbackReport" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avgParticipationScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avgTargetLanguageScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomTopicName" },
                },
                { kind: "Field", name: { kind: "Name", value: "note" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hasAtLeastOneFeedback" },
          },
          { kind: "Field", name: { kind: "Name", value: "userGivenName" } },
          { kind: "Field", name: { kind: "Name", value: "userFamilyName" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LessonFeedbackReportFragmentFragment, unknown>;
export const ClassRoomWithoutParticipantFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithoutParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomWithoutParticipantDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "feedbackReportUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomWithoutParticipantFragment, unknown>;
export const ProgressCheckResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProgressCheckResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProgressCheckResultDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accuracy" } },
          { kind: "Field", name: { kind: "Name", value: "recommendation" } },
          { kind: "Field", name: { kind: "Name", value: "vocabularyRange" } },
          { kind: "Field", name: { kind: "Name", value: "fluency" } },
          { kind: "Field", name: { kind: "Name", value: "comprehension" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "progressCheckId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckStartedAtUtc" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckLevel" },
          },
          { kind: "Field", name: { kind: "Name", value: "passed" } },
          { kind: "Field", name: { kind: "Name", value: "noShow" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProgressCheckResultFragment, unknown>;
export const ProgressCheckUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProgressCheckUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProgressCheckUserDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "alreadyGivenFeedback" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canImproveSpeakingLevel" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProgressCheckUserFragment, unknown>;
export const GetClassRoomByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClassRoomById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getClassRoomById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomParticipant" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "executedClassRoomCount" },
          },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomParticipant" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "feedbackReportUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClassRoomByIdQuery,
  GetClassRoomByIdQueryVariables
>;
export const UpsertLessonFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upsertLessonFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lessonFeedbacks" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "UpsertUserLessonFeedbackInput",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertLessonFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lessonFeedback" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lessonFeedbacks" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertLessonFeedbackMutation,
  UpsertLessonFeedbackMutationVariables
>;
export const GetLessonFeedbackReportByUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getLessonFeedbackReportByUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getLessonFeedbackReportByUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "LessonFeedbackReportFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LessonFeedbackReportFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserLessonFeedbackReport" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avgParticipationScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avgTargetLanguageScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomTopicName" },
                },
                { kind: "Field", name: { kind: "Name", value: "note" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hasAtLeastOneFeedback" },
          },
          { kind: "Field", name: { kind: "Name", value: "userGivenName" } },
          { kind: "Field", name: { kind: "Name", value: "userFamilyName" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLessonFeedbackReportByUserQuery,
  GetLessonFeedbackReportByUserQueryVariables
>;
export const GiveProgressCheckFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "giveProgressCheckFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "GiveProgressCheckFeedbackInput",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "giveProgressCheckFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GiveProgressCheckFeedbackMutation,
  GiveProgressCheckFeedbackMutationVariables
>;
export const GetProgressCheckResultByUserIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProgressCheckResultByUserId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getProgressCheckResultByUserId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProgressCheckResult" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProgressCheckResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProgressCheckResultDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accuracy" } },
          { kind: "Field", name: { kind: "Name", value: "recommendation" } },
          { kind: "Field", name: { kind: "Name", value: "vocabularyRange" } },
          { kind: "Field", name: { kind: "Name", value: "fluency" } },
          { kind: "Field", name: { kind: "Name", value: "comprehension" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "progressCheckId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckStartedAtUtc" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckLevel" },
          },
          { kind: "Field", name: { kind: "Name", value: "passed" } },
          { kind: "Field", name: { kind: "Name", value: "noShow" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProgressCheckResultByUserIdQuery,
  GetProgressCheckResultByUserIdQueryVariables
>;
export const GetProgressCheckUserListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProgressCheckUserList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "progressCheckId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getProgressCheckUserList" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "progressCheckId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "progressCheckId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheck" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ClassRoomWithoutParticipant",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ProgressCheckUser" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithoutParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomWithoutParticipantDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "feedbackReportUrl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProgressCheckUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProgressCheckUserDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "alreadyGivenFeedback" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canImproveSpeakingLevel" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProgressCheckUserListQuery,
  GetProgressCheckUserListQueryVariables
>;
