import { useQuery } from "@apollo/client";
import { getFragmentData, graphql } from "../gql";
import { LessonFeedbackReportFragment } from "./LessonFeedbackReportFragment.ts";

const GetUserLessonFeedbackReport = graphql(`
  query getLessonFeedbackReportByUser($userId: String!) {
    getLessonFeedbackReportByUser(userId: $userId) {
      ...LessonFeedbackReportFragment
    }
  }
`);
export function useUserLessonFeedbackReport(userId: string) {
  const { loading, data } = useQuery(GetUserLessonFeedbackReport, {
    variables: {
      userId,
    },
  });

  return {
    feedbackReport: getFragmentData(
      LessonFeedbackReportFragment,
      data?.getLessonFeedbackReportByUser,
    ),
    loading,
  };
}
