import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SpeakingClassLevel } from "../gql/graphql.ts";

const UpsertLessonFeedbackMutation = graphql(`
  mutation upsertLessonFeedback(
    $classRoomId: String!
    $lessonFeedbacks: [UpsertUserLessonFeedbackInput!]!
  ) {
    upsertLessonFeedback(
      classRoomId: $classRoomId
      lessonFeedback: $lessonFeedbacks
    )
  }
`);
export function useUpsertLessonFeedback() {
  const [upsertUserLessonFeedbackMutation, { loading }] = useMutation(
    UpsertLessonFeedbackMutation,
  );

  const upsertUserLessonFeedback = useCallback(
    async (input: {
      classRoomId: string;
      lessonFeedbacks: {
        userId: string;
        targetLanguageScore: number;
        participationScore: number;
        notes: string;
        noShow: boolean;
        differentSpeakingLevelSuggested?: SpeakingClassLevel;
      }[];
    }) => {
      await upsertUserLessonFeedbackMutation({
        variables: input,
      });
    },
    [upsertUserLessonFeedbackMutation],
  );

  return {
    loading,
    upsertUserLessonFeedback,
  };
}
