import { classNames } from "../Utils/classNames.ts";

export function CheckBox({
  value,
  label,
  onChange,
  disabled,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
  disabled?: boolean;
}) {
  return (
    <div
      onClick={() => {
        if (disabled) return;
        onChange(!value);
      }}
      className={classNames(
        "flex items-center space-x-2",
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
      )}
    >
      <div
        className={
          "p-px w-6 h-6 border-2 border-primary rounded-md flex-shrink-0"
        }
      >
        {value ? (
          <div className={"bg-primary w-full h-full rounded-md"} />
        ) : null}
      </div>
      <span>{label}</span>
    </div>
  );
}
