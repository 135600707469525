import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline/index.js";
function NavigationButtonWrapper({
  children,
  onClick,
}: PropsWithChildren<{
  onClick: () => void;
}>) {
  return (
    <div
      onClick={onClick}
      className={
        "bg-slate-200 rounded-full p-1 cursor-pointer shadow-lg shadow-slate-200/50"
      }
    >
      {children}
    </div>
  );
}

export function Tabs({ children }: PropsWithChildren) {
  const ref = useRef<HTMLUListElement>(null);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const getHasNext = useCallback(() => {
    if (!ref.current) return false;
    return (
      ref.current.scrollWidth - ref.current.scrollLeft > ref.current.clientWidth
    );
  }, []);

  const getHasPrev = useCallback(() => {
    if (!ref.current) return false;
    return ref.current.scrollLeft > 0;
  }, []);

  const updateHasNextPrev = useCallback(() => {
    setHasNext(getHasNext());
    setHasPrev(getHasPrev());
  }, [getHasNext, getHasPrev]);

  useEffect(() => {
    updateHasNextPrev();
  }, [updateHasNextPrev]);

  function getChildrenMeanWidth() {
    if (!ref.current || ref.current.children.length <= 0) return 0;
    return (
      Array.from(ref.current.children).reduce(
        (acc, child) => acc + child.clientWidth,
        0,
      ) / ref.current.children.length
    );
  }
  function onNext() {
    if (!ref.current) return;
    ref.current.scrollTo({
      left: ref.current.scrollLeft + getChildrenMeanWidth(),
      behavior: "smooth",
    });
  }

  function onPrev() {
    if (!ref.current) return;
    ref.current.scrollTo({
      left: ref.current.scrollLeft - getChildrenMeanWidth(),
      behavior: "smooth",
    });
  }

  return (
    <div className={"relative flex space-x-2 w-full"}>
      {hasPrev ? (
        <div className={"absolute left-0 h-full flex items-center"}>
          <NavigationButtonWrapper onClick={onPrev}>
            <ChevronLeftIcon className={"w-5 h-5"} />
          </NavigationButtonWrapper>
        </div>
      ) : null}
      <ul
        ref={ref}
        onScroll={updateHasNextPrev}
        className="flex flex-nowrap scrollbar-hide overflow-x-auto text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 w-full"
      >
        {children ?? null}
      </ul>
      {hasNext ? (
        <div className={"absolute right-0 h-full flex items-center"}>
          <NavigationButtonWrapper onClick={onNext}>
            <ChevronRightIcon className={"w-5 h-5"} />
          </NavigationButtonWrapper>
        </div>
      ) : null}
    </div>
  );
}
