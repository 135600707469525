import { graphql } from "../gql";

export const ClassRoomWithoutParticipantFragment = graphql(`
  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {
    id
    startedAtUtc
    speakingLevel
    feedbackReportUrl
  }
`);
