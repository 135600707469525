import { Layout } from "../UI/Layout/Layout.tsx";
import { useUserIdParamsOrThrow } from "../User/useUserIdParamsOrThrow.ts";
import { useUserLessonFeedbackReport } from "./useUserLessonFeedbackReport.ts";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useToLessonFeedbackReportUserList } from "./useToLessonFeedbackReportUserList.ts";
import { BreadCrumbsWrapper } from "../UI/Breadcrumb/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Breadcrumb/BreadCrumbsItem.tsx";
import { useClassRoomIdParamsOrThrow } from "../ClassRoom/useClassRoomIdParamsOrThrow.ts";
import { InfoFeedback } from "../UI/Feedback/InfoFeedback.tsx";
import { Card } from "../UI/Card.tsx";
import { useProgressCheckResultByUserId } from "../ProgressCheck/useProgressCheckResultByUserId.ts";
import { HorizontalLineDivider } from "../UI/HorizontalLineDivider.tsx";
import { getRecommendationLabel } from "../ProgressCheck/getRecommendationLabel.tsx";
import dayjs from "dayjs";
import { ProgressCheckResultFragment } from "../gql/graphql.ts";
function FeedbackReportScore({
  label,
  value,
}: {
  label: string;
  value: number;
}) {
  return (
    <div className={"space-x-3 flex flex-row items-center justify-between"}>
      <h3 className={"font-semibold"}>{label}</h3>
      <div className={"flex"}>
        <p className={"font-bold text-primary leading-4"}>{value.toFixed(1)}</p>
      </div>
    </div>
  );
}

function PcResultStatus({
  progressCheckResult,
}: {
  progressCheckResult: ProgressCheckResultFragment;
}) {
  if (progressCheckResult.noShow)
    return (
      <div
        className={"py-1 px-2 rounded-md bg-slate-400 text-sm text-slate-700"}
      >
        No Show
      </div>
    );

  return (
    <>
      {progressCheckResult.passed ? (
        <div
          className={"py-1 px-2 rounded-md bg-green-400 text-sm text-slate-700"}
        >
          Passed
        </div>
      ) : (
        <div
          className={"py-1 px-2 rounded-md bg-red-400 text-sm text-slate-700"}
        >
          Not passed
        </div>
      )}
    </>
  );
}

export const UserLessonFeedbackReportScreenPath =
  "/classroom/:classRoomId/users/:userId/lesson-feedback-report";

export function UserLessonFeedbackReportScreen() {
  const { userId } = useUserIdParamsOrThrow();
  const { loading: loadingProgressCheckResults, progressCheckResults } =
    useProgressCheckResultByUserId(userId);
  const { classRoomId } = useClassRoomIdParamsOrThrow();
  const { feedbackReport, loading: loadingUserLessonFeedback } =
    useUserLessonFeedbackReport(userId);
  const loading = loadingProgressCheckResults || loadingUserLessonFeedback;
  const { toLessonFeedbackReportUserList } =
    useToLessonFeedbackReportUserList();
  if (loading) return <FullPageLoading />;
  if (!feedbackReport) return null; //TODO return not found
  const userFullName = `${feedbackReport.userGivenName} ${feedbackReport.userFamilyName}`;
  return (
    <Layout>
      <div className={"space-y-4"}>
        <Card>
          <ScreenTitleBlock
            hideLineDivider
            title={`Report for ${userFullName}`}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem
                  onClick={() => toLessonFeedbackReportUserList(classRoomId)}
                  title={"Participant list"}
                />
                <BreadCrumbsItem title={`Report for ${userFullName}`} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"space-y-4"}>
          <div>
            <h4 className={"font-bold"}>Lessons</h4>
            <p>
              The lessons report covers the period from the last progress check
              taken by this student to the present.
            </p>
          </div>
          {feedbackReport.hasAtLeastOneFeedback ? (
            <div className={"space-y-4"}>
              <Card>
                <FeedbackReportScore
                  label={"Participation"}
                  value={feedbackReport.avgParticipationScore}
                />
              </Card>
              <Card>
                <FeedbackReportScore
                  label={"Target language"}
                  value={feedbackReport.avgTargetLanguageScore}
                />
              </Card>
              {feedbackReport.notes.length > 0 ? (
                <Card>
                  <div className={"space-y-3"}>
                    <h3 className={"font-semibold"}>Notes</h3>
                    <div className={"space-y-3"}>
                      {feedbackReport.notes.map((n, i) => {
                        return (
                          <div
                            className={
                              "space-y-2 border p-3 rounded-md border-slate-200/50"
                            }
                            key={i}
                          >
                            <p>
                              #{i + 1} Lesson on the topic:{" "}
                              {n.classRoomTopicName}
                            </p>
                            <p className={"p-3 bg-slate-100/50 rounded-md"}>
                              {n.note}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Card>
              ) : null}
            </div>
          ) : (
            <Card>
              <InfoFeedback
                title={"No feedback"}
                message={`${userFullName} has not received any feedback since their last progress check`}
              />
            </Card>
          )}
        </div>

        <div className={"space-y-4"}>
          <h4 className={"font-bold"}>Progress check results</h4>

          {progressCheckResults.length > 0 ? (
            progressCheckResults.map((r, i) => {
              return (
                <Card key={i}>
                  <div className={"space-y-4"}>
                    <div
                      className={"flex justify-between space-x-2 items-center"}
                    >
                      <h3 className={"font-semibold"}>
                        Taken on{" "}
                        {dayjs(r.progressCheckStartedAtUtc).format(
                          "ddd DD MMMM YYYY [at] HH:ss",
                        )}{" "}
                        - Level: {r.progressCheckLevel}
                      </h3>
                      <PcResultStatus progressCheckResult={r} />
                    </div>
                    {r.noShow ? null : (
                      <div className={"space-y-3"}>
                        <FeedbackReportScore
                          label={"Accuracy"}
                          value={r.accuracy}
                        />
                        <HorizontalLineDivider />

                        <FeedbackReportScore
                          label={"Comprehension"}
                          value={r.comprehension}
                        />
                        <HorizontalLineDivider />
                        <FeedbackReportScore
                          label={"Vocabulary Range"}
                          value={r.vocabularyRange}
                        />
                        <HorizontalLineDivider />
                        <FeedbackReportScore
                          label={"Fluency"}
                          value={r.fluency}
                        />
                        {r.recommendation.length > 0 ? (
                          <>
                            <HorizontalLineDivider />
                            <div className={"space-y-3"}>
                              <h3 className={"font-semibold"}>
                                Recommendations
                              </h3>
                              <ul className={"space-y-3"}>
                                {r.recommendation.map((rec, i) => {
                                  return (
                                    <li key={i}>
                                      <p className={"flex space-x-2"}>
                                        <span
                                          className={
                                            "block h-2 w-2 bg-primary rounded-full relative top-2 flex-shrink-0"
                                          }
                                        />
                                        <span>
                                          {getRecommendationLabel(rec)}
                                        </span>
                                      </p>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </>
                        ) : null}

                        {r.notes ? (
                          <>
                            <HorizontalLineDivider />
                            <div className={"space-y-3"}>
                              <h3 className={"font-semibold"}>Notes</h3>
                              <p>{r.notes}</p>
                            </div>
                          </>
                        ) : null}
                      </div>
                    )}
                  </div>
                </Card>
              );
            })
          ) : (
            <Card>
              <InfoFeedback
                title={"No progress check"}
                message={`${userFullName} has not taken any progress check`}
              />
            </Card>
          )}
        </div>
      </div>
    </Layout>
  );
}
