import { Layout } from "../UI/Layout/Layout.tsx";
export const SentLessonFeedbackThankYouPagePath =
  "/classroom/:classRoomId/sent-feedback-thank-you";
export function SentLessonFeedbackThankYouPage() {
  return (
    <Layout>
      <div className={"flex-1 flex items-center justify-center"}>
        <div
          className={
            "bg-white rounded-xl p-4 shadow-lg shadow-slate-100 space-y-1"
          }
        >
          <h1 className={"font-bold text-2xl"}>Thank you! 😊</h1>
          <p>Your feedback is valuable for helping our students improve</p>
        </div>
      </div>
    </Layout>
  );
}
