import { InformationCircleIcon } from "@heroicons/react/24/outline";
type Props = {
  title: string;
  message: string;
};
export function InfoFeedback({ title, message }: Props) {
  return (
    <div className={"flex w-full"}>
      <div className={"bg-blue-200 rounded-md p-3 flex space-x-2 w-full"}>
        <div className={"flex items-center"}>
          <div className={"bg-blue-500 rounded-full p-2"}>
            <InformationCircleIcon className={"h-6 w-6 text-white"} />
          </div>
        </div>
        <div className={"text-slate-700"}>
          <h3 className={"font-bold"}>{title}</h3>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
