import { graphql } from "../gql";

export const LessonFeedbackReportFragment = graphql(`
  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {
    avgParticipationScore
    avgTargetLanguageScore
    notes {
      classRoomStartDateUtc
      classRoomTopicName
      note
    }
    hasAtLeastOneFeedback
    userGivenName
    userFamilyName
  }
`);
