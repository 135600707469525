import { useCallback, useEffect, useRef, useState } from "react";
type MessagePopupControlBag = {
  title: string;
  description: string;
  show: boolean;
};
export function useMessagePopupController(timeoutInMs: number) {
  const [controlBag, setControlBag] = useState<MessagePopupControlBag>();
  const show = controlBag?.show ?? false;
  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (show) {
      timeoutRef.current = setTimeout(() => {
        setControlBag(undefined);
      }, timeoutInMs);
    }
  }, [show, timeoutInMs]);
  const showPopup = useCallback((title: string, description: string) => {
    setControlBag({ title, description, show: true });
  }, []);

  return {
    show: show,
    title: controlBag?.title ?? "",
    description: controlBag?.description ?? "",
    showPopup,
  };
}
