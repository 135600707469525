import { classNames } from "../Utils/classNames.ts";

type Props = {
  title: string;
  onClick?: () => void;
};
export function BreadCrumbsItem({ title, onClick }: Props) {
  return (
    <p
      className={classNames(
        "text-sm",
        onClick ? "underline cursor-pointer" : "",
      )}
      onClick={onClick}
    >
      {title}
    </p>
  );
}
