import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  GiveLessonFeedbackScreen,
  GiveLessonFeedbackScreenPath,
} from "./LessonFeedback/GiveLessonFeedbackScreen.tsx";
import { ApolloProvider } from "@apollo/client";
import { useMemo } from "react";
import { useCreateApolloClient } from "./ApolloClient/useCreateApolloClient.ts";
import {
  LessonFeedbackReportUserListScreen,
  LessonFeedbackReportUserListScreenPath,
} from "./LessonFeedback/LessonFeedbackReportUserListScreen.tsx";
import {
  UserLessonFeedbackReportScreen,
  UserLessonFeedbackReportScreenPath,
} from "./LessonFeedback/UserLessonFeedbackReportScreen.tsx";
import {
  SentLessonFeedbackThankYouPage,
  SentLessonFeedbackThankYouPagePath,
} from "./LessonFeedback/SentLessonFeedbackThankYouPage.tsx";
import { TestScreen, TestScreenPath } from "./TestScreen.tsx";
import {
  GiveProgressCheckFeedbackScreen,
  GiveProgressCheckFeedbackScreenPath,
} from "./ProgressCheck/GiveProgressCheckFeedbackScreen.tsx";
import {
  GiveProgressCheckFeedbackThankYouScreen,
  GiveProgressCheckFeedbackThankYouScreenPath,
} from "./ProgressCheck/GiveProgressCheckFeedbackThankYouScreen.tsx";

function App() {
  const { createApolloClient } = useCreateApolloClient();
  const apolloClient = useMemo(
    () => createApolloClient(),
    [createApolloClient],
  );
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path={GiveLessonFeedbackScreenPath}
            element={<GiveLessonFeedbackScreen />}
          />
          <Route
            path={LessonFeedbackReportUserListScreenPath}
            element={<LessonFeedbackReportUserListScreen />}
          />
          <Route
            path={UserLessonFeedbackReportScreenPath}
            element={<UserLessonFeedbackReportScreen />}
          />
          <Route
            path={SentLessonFeedbackThankYouPagePath}
            element={<SentLessonFeedbackThankYouPage />}
          />
          <Route
            path={GiveProgressCheckFeedbackScreenPath}
            element={<GiveProgressCheckFeedbackScreen />}
          />
          <Route
            path={GiveProgressCheckFeedbackThankYouScreenPath}
            element={<GiveProgressCheckFeedbackThankYouScreen />}
          />
          <Route path={TestScreenPath} element={<TestScreen />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
