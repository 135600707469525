export function ProgressBar({
  max,
  current,
  label,
}: {
  max: number;
  current: number;
  label: string;
}) {
  const completePercentage = (current / max) * 100;
  return (
    <div className={"space-y-2"}>
      <div
        className={
          "flex items-center justify-between w-full text-xs text-slate-600"
        }
      >
        <p className={"font-bold"}>{label}</p>
        <span>
          {current} / {max}
        </span>
      </div>
      <div className={"bg-slate-200 w-full h-2 rounded-2xl overflow-hidden"}>
        <div
          className={`bg-primary h-full`}
          style={{
            width: `${completePercentage}%`,
          }}
        />
      </div>
    </div>
  );
}
