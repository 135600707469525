import { classNames } from "../Utils/classNames.ts";
import { LoaderSize } from "./LoaderSize.tsx";

type Props = {
  size: LoaderSize;
};
export function PrimaryLoadingIndicator({ size }: Props) {
  function getSizeClasses(): string {
    switch (size) {
      case LoaderSize.Small:
        return "h-5 w-5";
      case LoaderSize.Large:
        return "h-10 w-10";
    }
  }
  return (
    <div
      className={classNames(
        "border-2 border-t-primary border-gray-200/50 rounded-full animate-spin",
        getSizeClasses(),
      )}
    />
  );
}
