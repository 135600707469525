import { MouseEvent } from "react";
import { WhiteLoadingIndicator } from "./Loading/WhiteLoadingIndicator.tsx";

type Props = {
  label: string;
  href?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  loading?: boolean;
};
export function PrimaryButton({ label, href, onClick, loading }: Props) {
  return (
    <a
      href={href}
      onClick={(e) => {
        if (!href) e.preventDefault();
        onClick?.(e);
      }}
      className={
        "hover:cursor-pointer hover:brightness-110 bg-primary rounded-lg text-white px-10 py-2 flex items-center space-x-2 whitespace-nowrap text-center"
      }
    >
      <span>{label}</span>
      {loading ? <WhiteLoadingIndicator /> : null}
    </a>
  );
}
