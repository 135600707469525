import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { LessonFeedbackReportUserListScreenPath } from "./LessonFeedbackReportUserListScreen.tsx";

export function useToLessonFeedbackReportUserList() {
  const navigate = useNavigate();

  const toLessonFeedbackReportUserList = useCallback(
    (classRoomId: string) => {
      navigate(
        LessonFeedbackReportUserListScreenPath.replace(
          ":classRoomId",
          classRoomId,
        ),
      );
    },
    [navigate],
  );

  return {
    toLessonFeedbackReportUserList,
  };
}
