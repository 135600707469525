import { PrimaryButton } from "../PrimaryButton.tsx";
import { HorizontalLineDivider } from "../HorizontalLineDivider.tsx";
import { ReactElement, ReactNode } from "react";

export function ScreenTitleBlock({
  title,
  ctaLabel,
  ctaOnClick,
  loading,
  breadCrumbs,
  subTitle,
  hideLineDivider,
}: {
  title: string;
  ctaLabel?: string;
  ctaOnClick?: () => void;
  loading?: boolean;
  breadCrumbs?: ReactElement;
  subTitle?: ReactNode;
  hideLineDivider?: boolean;
}) {
  function hasCta(): boolean {
    return !!(ctaLabel && ctaOnClick);
  }
  return (
    <div>
      <div className={"flex flex-col space-y-4"}>
        {breadCrumbs ?? null}
        <div className={"flex items-center justify-between"}>
          <h1 className={"font-bold text-2xl"}>{title}</h1>

          {hasCta() ? (
            <PrimaryButton
              loading={loading}
              onClick={ctaOnClick}
              label={ctaLabel ?? ""}
            />
          ) : null}
        </div>
      </div>
      {subTitle ?? null}
      {hideLineDivider ? null : <HorizontalLineDivider className={"my-8"} />}
    </div>
  );
}
