import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline/index.js";
import { Fragment } from "react";
import { classNames } from "./Utils/classNames.ts";
import { isDefined } from "../isDefined.ts";

export type SelectOption<T> = {
  name: string;
  id: T;
};

type Props<T> = {
  options: SelectOption<T>[];
  onChange: (option: T | null) => void;
  value?: T;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  clearable?: boolean;
  disabledReason?: string;
};
export function Select<T>({
  options,
  value,
  onChange,
  placeholder,
  errorMessage,
  disabled,
  clearable,
  disabledReason,
}: Props<T>) {
  const selectedOption = isDefined(value)
    ? options.find((o) => {
        return o.id === value;
      })
    : undefined;

  function getLabel() {
    if (selectedOption?.name) return selectedOption?.name;
    return placeholder ?? "";
  }

  return (
    <div className="w-full flex flex-col space-y-2">
      <Listbox disabled={disabled} value={value} onChange={onChange}>
        <div className="relative">
          <Listbox.Button
            className={classNames(
              errorMessage ? "border-red-500" : "border-slate-200",
              disabled ? "bg-slate-100 cursor-not-allowed" : "bg-white",
              "min-h-[42px] border relative w-full cursor-default rounded-lg py-2 pl-3 pr-14 text-left",
            )}
          >
            <span
              className={classNames(
                selectedOption?.name ? "text-gray-900" : "text-gray-400",
                "block truncate",
              )}
            >
              {getLabel()}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
            {clearable ? (
              <span className="cursor-pointer absolute inset-y-0 right-5 flex items-center pr-2">
                <XCircleIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null);
                  }}
                  className="h-5 w-5 text-gray-400 hover:text-gray-700"
                  aria-hidden="true"
                />
              </span>
            ) : null}
          </Listbox.Button>
          {disabled && disabledReason ? (
            <p className={"mt-1 text-sm"}>{disabledReason}</p>
          ) : null}
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {options.map((option, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active ? "bg-primary text-white" : "text-gray-900"
                    }`
                  }
                  value={option.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {errorMessage ? <p className={"text-red-500"}>{errorMessage}</p> : null}
    </div>
  );
}
